<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Proyek *</label>
        <vs-select class="w-full" v-model="data.id_proyek">
          <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Rekanan *</label>
        <vx-input-group>
          <vs-input placeholder="Pilih Rekanan" :value="data.nama_rekanan" @click="modalRekanan.active = true" :disabled="!data.id_proyek"/>
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalRekanan.active = true" :disabled="!data.id_proyek"/>
            </div>
          </template>
        </vx-input-group>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Jenis PO *</label>
        <vs-select class="w-full" v-model="data.jenis_po">
          <vs-select-item v-for="(it, index) in jenisPo" :key="index" :value="it" :text="it"/>
        </vs-select>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">No. PO *</label>
        <vs-input class="w-full" :value="data.no_po || 'Generate Otomatis'" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Tgl PO *</label>
        <flat-pickr class="w-full" v-model="data.tgl_po"></flat-pickr>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan *</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan"/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modal item rekanan-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Rekanan"
              :active="modalRekanan.active"
              v-on:update:active="modalRekanan.active = $event">
      <Rekanan :selectable="true"
               :externalFilter="filterRekanan"
               @selected="onSelectedModalRekanan"/>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Rekanan from '@/views/pages/master/rekanan/Rekanan'
import _ from 'lodash'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import constant from '@/constant/constant'
import moment from 'moment'

export default {
  name: 'TabHeader',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    Rekanan,
    ValidationErrors,
    flatPickr
  },
  mounted () {
    this.commitDataToStore(this.data)
    this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.proyek.poAdd.errors.tabHeader.messages
    },
    filterRekanan () {
      return this.data.id_proyek ? { id_proyek: this.data.id_proyek } : null
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      modalRekanan: {
        active: false
      },
      data: {
        id_proyek: null,
        jenis_po: constant.JENIS_PO.JASA,
        tgl_po: moment().format('YYYY-MM-DD')
      },
      proyeks: [],
      jenisPo: [constant.JENIS_PO.JASA, constant.JENIS_PO.BARANG]
    }
  },
  methods: {
    onSelectedModalRekanan (item) {
      this.data = {
        ...this.data,
        id_rekanan: item.id,
        nama_rekanan: item.nama
      }
      this.modalRekanan.active = false
    },

    onChangeProyek (value) {
      this.data.id_rekanan = null
      this.data.nama_rekanan = null
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep({
        ...value
      })
      this.$store.commit('proyek/poAdd/SET_TAB_HEADER', payload)
    }, 500),

    next () {
      this.$store.commit('proyek/poAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
